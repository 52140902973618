@import "vars";

.severity {
  &.critical {
    color: $critical;
  }
  &.high {
    color: $high;
  }
  &.medium {
    color: $medium;
  }
  &.low {
    color: $low;
  }
  &.informational {
    color: $informational;
  }
  &.success {
    color: $success;
  }
  &.unreviewed {
    color: $dark;
  }
}

.severity--label-bordered {
  border-color: #64bbe3;
  background-color: transparent !important;
  &.critical {
    border-color: $critical;
  }
  &.high {
    border-color: $high;
  }
  &.medium {
    border-color: $medium;
  }
  &.low {
    border-color: $low;
  }
  &.informational {
    border-color: $informational;
  }
}

.severity--blockquote {
  margin: 0px !important;
  border-color: #64bbe3;
  &.critical {
    border-color: $critical !important;
  }
  &.high {
    border-color: $high !important;
  }
  &.medium {
    border-color: $medium !important;
  }
  &.low {
    border-color: $low !important;
  }
  &.informational {
    border-color: $informational !important;
  }
}
