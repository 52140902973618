@import "vars";
@import "sidebar";
@import "primitives";

.container-main {
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
  .container-dashboard {
    padding-top: 55px;
    width: calc(100% - 125px);
    margin-left: 100px;
    position: relative;
    .sidebar {
      padding-top: 55px;
    }
    .dashboard-content {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      position: relative;
    }
  }
}

.cisco-confidential-banner {
  background-repeat: repeat-x;
  background-position: center -2px;
  height: 75px;
}

.flex-wrapper {
  display: flex;
  min-height: 95vh;
  flex-direction: column;
  justify-content: space-between;
}
