@import "cisco-ui/src/scss/cui-standard.scss";

html,
body,
#root {
  overflow-x: hidden;
}

.solis {
  height: 100%;
  width: 100%;
}
