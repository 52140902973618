/* COLORS */

$primary: #00bceb;
$secondary: #1e4471;
$tertiary: #0d274d;
$link: #0175a2;
$success: #6abf4b;
$danger: #e2231a;
$warning: #fbab18;
$warning-alt: #eed202;
$dark: #495057;
$light: #ced4da;
$gray-200: #f2f2f2;

$critical: $danger;
$high: #f66a0a;
$medium: $warning;
$low: $warning-alt;
$informational: $secondary;

/* MIXINS */
@mixin fadeDown($name, $from, $to) {
  @keyframes #{$name} {
    0% {
      transform: translateY($from, 0, 0);
      opacity: 0;
    }
    100% {
      transform: translateY($to, 0, 0);
      opacity: 1;
    }
  }
}
