// Certain internal look/feel requests fall outside of the CUI library.
// These styles ensure that custom buttons, if needed, mimic the CUI's req's globally.
button {
  cursor: pointer;
  border: none;
  height: fit-content;
  background-color: inherit;
  position: relative;
}

// Margin applied inside the application and target control components.
.btn-add-tag {
  margin-top: 32px;
}

// Wrapper that groups actions/toggles for editing (edit, delete, copy, save, cancel, etc)
.edit-control-block {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 75px;
  right: 0px;
  padding: 10px;
}

// Primarily in use for application control, target control, and scan config components.
// Provides a brief description on hover for buttons that are icon only.
.button-with-tip {
  .label {
    display: flex;
    justify-content: center;
    color: inherit;
    width: 100% !important;
    height: 100%;
    border-radius: 100%;
    background-color: inherit !important;
  }

  // TODO - track down the carot at the bottom of toooltip
  //      - it inherits the background but its pseudo doesnt

  // .info-tooltip::after {
  //   background-color: inherit;
  // }
  // .info-tooltip::before {
  //   background-color: inherit;
  // }
}

.btn-wizard--submit {
  display: flex !important;
}

.cui .dropdown .btn:after {
  opacity: 0;
  border-left: 0px;
  border-right: 0px;
  margin-left: 0px;
}

.btn-tag {
  background-color: transparent !important;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}
