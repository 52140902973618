// Sidebar is intially translated left out of the view.
// When global ready state is available an active class/animation is added.
// This prevents the user from selecting an option before data is available.

.sidebar {
  position: absolute;
  &.sidebar-active {
    animation-name: fadeInLeft;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    animation-timing-function: cubic-bezier(0.2, 0, 0.38, 0.9);
  }
  &.hidden {
    transform: translate(-80px);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translateX(-80px);
  }
  95% {
    opacity: 1;
    transform: translateX(0px);
  }
  100% {
    position: fixed;
    transform: translateX(0px);
  }
}

.sidebar-header-button {
  display: inline-block !important;
  color: #f2f2f2 !important;
  background-color: #343a40 !important;
  border-color: #343a40 !important;
}

.sidebar-header-button:hover {
  color: #adb5bd !important;
}

.sidebar-input {
  input {
    color: #f2f2f2 !important;
    background-color: #6c757d !important;
  }
  input::placeholder {
    color: #dee2e6;
  }
}
